.activity-selector {
    width: 100%;
}
.activity-selector input {
    width:100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.activity-search {
    position: relative;
}

.search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.search-result-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.search-result-item:hover {
    background-color: #f0f0f0;
}

.selected-activities h5 {
    margin-bottom: 10px;
}

.selected-activity-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #f8f9fa;
    margin-bottom: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.remove-activity-button {
    color: #dc3545;
    border: none;
    background: none;
    cursor: pointer;
    padding: 4px 8px;
}

.no-activities-message {
    color: #666;
    font-style: italic;
} 